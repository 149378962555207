<template>
  <div class="list-chart-container">
    <div v-for="(l) in list" :key="l.key">
      <div class="label" :style="'width:' + labelWidth + ';'">{{ l.name }}</div>
      <div class="line-body">
        <div class="line1" :style="getLineRate(l.value, max) + 'background-color:' + lineColor + ';'"></div>
        <div class="text" :style="`color: ${lineColor};`">{{ l.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "diseaseChart",
  data () {
    return {
      labelWidth: '1.6rem',

    }
  },
  computed: {
    max () {
      const max = this.list.reduce((m, l) => {
        const sum = l.value
        if (m >= sum) {
          return m
        } else {
          return sum
        }
      }, 0)
      return max * 1.4
    },
  },
  props: {
    list: {
      default: () => [],
      type: Array
    },
    lineColor: {
      default: "#fff",
      type: String
    }
  },
  methods: {
    getLineRate (value, max) {
      const rate = value / max * 100
      return `width:${rate}%;`
    }
  }
}
</script>

<style scoped lang="scss">
.list-chart-container {
  position: relative;
  width: 100%;
  background-color: #f4f6fc;

  >div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .label {
      flex: 0 0 auto;
      height: 0.64rem;
      line-height: 0.64rem;
      text-align: right;
      padding-right: 0.2rem;
    }

    .line-body {
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      height: 0.36rem;

      .line1 {
        height: 0.14rem;
        border-top-right-radius: 0.14rem;
        border-bottom-right-radius: 0.14rem;
      }



      .text {
        padding-left: 0.36rem;
        color: #cf2434;
      }
    }
  }
}
</style>
<template>
  <div class="list-chart-container">
    <div v-for="(l) in list" :key="l.key">
      <div class="label" :style="'width:' + labelWidth + ';'">{{ l.name }}</div>
      <div class="line-body">
        <div class="line1" v-if="l.HEA1" :style="getLineRate(l.HEA1, max)"></div>
        <div class="line2" v-if="l.HEA2" :style="getLineRate(l.HEA2, max)"></div>
        <div class="line3" v-if="l.HEA3" :style="getLineRate(l.HEA3, max)"></div>
        <div v-if="l.value < 100" class="text1">{{ l.value }}</div>
        <div v-else-if="l.value >= 100 && l.value < 200" class="text2">{{ l.value }}</div>
        <div v-else-if="l.value >= 200" class="text3">{{ l.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "listChart",
  data () {
    return {
      labelWidth: '1.6rem'
    }
  },
  computed: {
    max () {
      const max = this.list.reduce((m, l) => {
        const sum = l.HEA1 + l.HEA2 + l.HEA3
        if (m >= sum) {
          return m
        } else {
          return sum
        }
      }, 0)
      return max * 1.4
    },
  },
  props: {
    list: {
      default: () => [],
      type: Array
    },
  },
  methods: {
    getLineRate (value, max) {
      const rate = value / max * 100
      return `width:${rate}%;`
    }
  }
}
</script>

<style scoped lang="scss">
.list-chart-container {
  position: relative;
  width: 100%;
  background-color: #f4f6fc;

  >div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .label {
      flex: 0 0 auto;
      height: 0.64rem;
      line-height: 0.64rem;
      text-align: right;
      padding-right: 0.2rem;
      border-right: 0.02rem solid #cbd6dc;
    }

    .line-body {
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      height: 0.36rem;

      >div {
        flex: 0 0 auto;
        height: 100%;
        position: relative;

        &::after {
          content: "";
          width: 0.36rem;
          height: 0.36rem;
          position: absolute;
          right: -0.35rem;
          top: 0;
          z-index: 2;
          clip-path: polygon(0 0, 50% 50%, 0 100%);
        }
      }

      .line1 {
        background-image: linear-gradient(to bottom, #d7df22, #319942);

        &::after {

          background-image: linear-gradient(to bottom, #d7df22, #319942);
        }
      }

      .line2 {
        background-image: linear-gradient(to bottom, #fbb03f, #ff5a23);

        &::after {
          background-image: linear-gradient(to bottom, #fbb03f, #ff5a23);
        }
      }

      .line3 {
        background-image: linear-gradient(to bottom, #ff7b4f, #cf2434);

        &::after {
          background-image: linear-gradient(to bottom, #ff7b4f, #cf2434);
        }
      }

      .text1 {
        padding-left: 0.36rem;
        color: #319942;
      }

      .text2 {
        padding-left: 0.36rem;
        color: #ff5a23;
      }

      .text3 {
        padding-left: 0.36rem;
        color: #cf2434;
      }


    }
  }
}
</style>